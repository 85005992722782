import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from '../client/client.types';
@Injectable({
    providedIn: 'root',
})
export class BillingService implements Resolve<any> {
    routeParams: any;
    client: Client;
    // payments: Payments;
    program: any;
    onBillingChanged: BehaviorSubject<any>;
    params: any;
    programParams: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param router
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router
    ) {
        // Set the defaults
        this.onBillingChanged = new BehaviorSubject({});
        this.params = new HttpParams()
            .set('_format', 'json')
            .set('cache', 'false');
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getClient()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get client
     *
     * @returns {Promise<any>}
     */
    getClient(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user'));
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    `${environment.apiUrl}/enutri/client/` +
                        user.clientProfileId,
                    { params: this.params }
                )
                .subscribe(
                    (response: any) => {
                        this.client = response;
                        this.onBillingChanged.next(this.client);
                        resolve(this.client);
                    },
                    (error) => {
                        console.error(error);
                    },
                    reject
                );
        });
    }

    /**
     * Add rate
     *
     * @param rate
     * @returns {Promise<any>}
     */
    addRate(rate): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(`${environment.apiUrl}/enutri/rate`, rate, {
                    params: this.params,
                })
                .subscribe((response: any) => {
                    resolve(response);
                    this.getClient();
                }, reject);
        });
    }

    /**
     * Add Google Reveiw Check
     *
     * @param ga
     * @returns {Promise<any>}
     */
    checkGoogleReview(ga): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .patch(`${environment.apiUrl}/enutri/rate/` + ga.clientId, ga, {
                    params: this.params,
                })
                .subscribe((response: any) => {
                    resolve(response);
                    this.getClient();
                }, reject);
        });
    }
}
